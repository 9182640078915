import React from "react";
import PlainCard from "../../components/leftGridCard";
import "./LeftGrid.scss";

const Infoview = (props) => {
  return (
    <div className="">
      <PlainCard
        HoverCardWithBtnCustomClass="left-card-bg"
        style={{ height: "750px" }}
        HoverCardWithBtntitle={props.SixHoverCardData.HoverCardWithBtntitle1}
        HoverCardWithBtnContent={
          props.SixHoverCardData.HoverCardWithBtnContent1
        }
        HoverCardWithBtnIconAlt={
          props.SixHoverCardData.HoverCardWithBtnIconAlt1
        }
        HoverCardIconClass={props.HoverCardIconClass}
        keyPointsBig1={props.SixHoverCardData.keyPointsBig1}
        keyPointsBig2={props.SixHoverCardData.keyPointsBig2}
        keyPointsBig3={props.SixHoverCardData.keyPointsBig3}
        keyPoints1={props.SixHoverCardData.keyPoints1}
        keyPoints2={props.SixHoverCardData.keyPoints2}
        keyPoints3={props.SixHoverCardData.keyPoints3}
      />
    </div>
  );
};

export default Infoview;
