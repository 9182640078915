import React from "react";
import "./leftGridCard.scss";

const LeftGridCard = (props) => {
  return (
    <div className={`left-grid-card-main ${props.HoverCardWithBtnCustomClass}`}>
      <div>
        <div className="plain-card-title">{props.HoverCardWithBtntitle}</div>
        <div className="fz-20">{props.HoverCardWithBtnContent}</div>
        <div className="mt-30"></div>
        <div className="key-points-big">{props.keyPointsBig1}</div>
        <div className="key-points">{props.keyPoints1}</div>
        <div className="mt-30"></div>
        <div className="key-points-big">{props.keyPointsBig2}</div>
        <div className="key-points">{props.keyPoints2}</div>
        <div className="mt-30"></div>
        <div className="key-points-big">{props.keyPointsBig3}</div>
        <div className="key-points">{props.keyPoints3}</div>
        {/* {props.HoverCardWithBtnsubtitle && <div className="plain-card-subtitle">{props.HoverCardWithBtnsubtitle}</div>}
        {props.HoverCardWithBtnlabel && <div className="white-line"></div>}
        <div className="plain-card-button-main">         
            {props.HoverCardWithBtnlabel && (
              <Button
                customClassName="plain-card-button hover-card-button"
                variant={props.HoverCardWithBtnvariant}
                color={props.HoverCardWithBtncolor}
                label={props.HoverCardWithBtnlabel}
                routepath={props.route}
              />
            )}
            {props.HoverCardWithBtnIcon && ( <img className={props.HoverCardIconClass} src={props.HoverCardWithBtnIcon} alt={props.HoverCardWithBtnIconAlt} />)}
        </div> */}
      </div>
    </div>
  );
};

export default LeftGridCard;
